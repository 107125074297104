@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
@font-face {
  font-family: 'gilroy';
  src: local('gilroy'),
       url('./fonts/gilroy-regular.ttf') format('ttf');
  font-weight: 600;
};
}
 
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Manrope:wght@200..800&family=Oxygen:wght@300;400;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Zen+Dots&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee&family=Cinzel:wght@400..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Manrope:wght@200..800&family=Marcellus+SC&family=Roboto+Slab:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee&family=Cinzel:wght@400..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Luxurious+Roman&family=Manrope:wght@200..800&family=Marcellus+SC&family=Roboto+Slab:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee&family=Cinzel:wght@400..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Luxurious+Roman&family=Manrope:wght@200..800&family=Marcellus+SC&family=Roboto+Slab:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee&family=Cinzel:wght@400..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Luxurious+Roman&family=Manrope:wght@200..800&family=Marcellus+SC&family=Roboto+Slab:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee&family=Cinzel:wght@400..900&family=Heebo:wght@100..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Luxurious+Roman&family=Manrope:wght@200..800&family=Marcellus+SC&family=Roboto+Slab:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');







body {
    @apply font-poppins;
  }